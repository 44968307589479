<template>
  <div>
    <sign-up-modal v-if="hasNewSignupFlow"/>
    <div
      v-if="collaboration"
      :class="user.subscription && !thesisSlot ? 'top-margin' : ''"
    >
      <!-- true/false will be later changed to thesis slots-->
      <MasterHeader />
      <div class="container">
        <div class="row">
          <div class="col-12" v-if="user.userId">
            <div v-if="isUserOwner" class="mb-1">
              <router-link to="/collaboration/my" class="btn btn-sm btn-link"
                ><i class="fas fa-angle-left"></i> My Collaborations
              </router-link>
            </div>

            <div v-else class="mb-1">
              <router-link to="/collaborations" class="btn btn-sm btn-link"
                ><i class="fas fa-angle-left"></i> Find
                collaborations</router-link
              >
            </div>
          </div>
        </div>

        <div class="row" v-if="isLoaded">
          <div class="col-12 col-lg-9">
            <div v-if="isUserOwner && collaboration.status !== 'collaborating'">
              <!-- <div class="erow-cta" v-if="collaboration.status === 'draft'">
                                <h4><i class="far fa-life-ring"></i> Last step</h4> <span data-toggle="modal" data-target="#collabInvite">Invite your group</span> and <span @click="changeStatus('published')"><u>publish</u></span> your collaboration.
              </div> -->
              <div
                class="erow-cta mb-2"
                v-if="collaboration.status === 'published'"
              >
                <h4>Published</h4>
                Your collaboration is now published and available for interview
                requests.
              </div>

              <div
                class="erow-cta mb-2"
                v-if="collaboration.status == 'review'"
              >
                <h4>IN REVIEW WITH EXCELERATE</h4>
                Please allow the Excelerate team 48 hours for the interview. You
                will not be able to edit the thesis topic while we are reviewing
                it - You will receive an email when it has been reviewed. If
                approved, we will publish your thesis for you.
              </div>
              <div
                class="erow-cta mb-2"
                v-else-if="collaboration.status === 'approved'"
              >
                <h4>READY TO BE PUBLISHED</h4>
                Your thesis topic is not published and therefore not visible to
                companies.
              </div>
            </div>

            <div v-if="isUserOwner && collaboration.status === 'collaborating'">
              <div class="erow-cta mb-2">
                <h4>Collaboration Confirmed!</h4>
                <h6 class="pt-2">
                  Congratulations. Your collaboration has been confirmed by the
                  company.
                </h6>
              </div>
            </div>
            <div
              v-if="isUserOffering && collaboration.status === 'collaborating'"
            >
              <div class="erow-cta mb-2">
                <h4>Congratulations!</h4>
                <h6 class="pt-2">
                  Collaboration confirmed with the student group.
                </h6>
              </div>
            </div>
            <div class="widget mb-3">
              <div class="widget-splash splash-line"></div>
              <div class="widget-header sticky">
                <h4>Topic</h4>
                <div class="widget-navigation">
                  <router-link
                    :to="'/collaboration/edit/' + this.$route.params.id"
                    class="widget-nav"
                    v-if="isUserOwner && isEditable"
                    ><i class="fas fa-pen"></i> Edit</router-link
                  >
                </div>
              </div>
              <div class="widget-body">
                <div class="collaboration-thesis">
                  <h1>{{ collaboration.title }}</h1>
                  <h2 class="pt-2">{{ collaboration.subtitle }}</h2>
                  <p v-if="collaboration.keywords" class="keywords pt-2">
                    <span
                      class="keyword"
                      v-for="(keyword, index) in collaboration.keywords"
                      :key="index"
                      >{{ keyword }}</span
                    >
                  </p>
                  <div class="collaboration-body toggle-this">
                    <template v-if="collaboration.content">
                      <h6>Thesis Description</h6>
                      <div
                        class="collaboration-thesis-body"
                        v-html="collaboration.content"
                      ></div>
                    </template>
                    <!-- No longer showing methodology -->
                    <!-- <template v-if="collaboration.methodology">
                      <h6>Methodology</h6>
                      <div
                        class="collaboration-thesis-body"
                        v-html="collaboration.methodology"
                      ></div>
                    </template> -->
                    <template v-if="collaboration.value">
                      <h6>Value</h6>
                      <div
                        class="collaboration-thesis-body"
                        v-html="collaboration.value"
                      ></div>
                    </template>
                    <template v-if="collaboration.motivation">
                      <h6>Motivation</h6>
                      <div
                        class="collaboration-thesis-body"
                        v-html="collaboration.motivation"
                      ></div>
                    </template>
                    <template v-if="collaboration.whyus">
                      <h6>Why us</h6>
                      <div
                        class="collaboration-thesis-body"
                        v-html="collaboration.whyus"
                      ></div>
                    </template>

                    <div class="toggler" :key="2" v-show="isReadMore">
                      <span class="readMore" @click="toggle">Load More</span>
                    </div>
                    <div class="toggler" :key="3" v-show="isReadLess">
                      <span class="readLess" @click="toggle">Load Less</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="widget mb-2"
              v-if="
                (isUserOwner && collaborationOffers.length > 0) ||
                isUserOffering
              "
            >
              <div class="widget-header sticky">
                <h4>Negotiations</h4>
                <div class="widget-navigation"></div>
              </div>
              <div class="widget-body">
                <!-- offers loop -->
                <div v-if="isUserOwner || isUserOffering">
                  <!-- student side -->
                  <template
                    v-if="isUserOwner && collaborationOffers.length > 0"
                  >
                    <div
                      class="erow row d-flex highlighted"
                      v-for="(offer, index) in collaborationOffers"
                      :key="index"
                    >
                      <div class="negotations-company-header p-2">
                        <!--image  -->
                        <!-- comment-f1 -->
                        <div
                          v-if="
                            offer.offer.companyDetails.imageUrl &&
                            offer.offer.status !== 2
                          "
                          class="image-container my-2 align-self-center"
                        >
                          <img
                            :src="offer.offer.companyDetails.imageUrl"
                            alt="comapanyLogo"
                            class=""
                          />
                        </div>
                        <div class="d-flex">
                          <div
                            class="flex-grow-1"
                            v-if="offer.offer.companyDetails.imageUrl"
                          >
                            <div
                              v-if="offer.offer.companyDetails.industry"
                              class="company-industry-section"
                            >
                              <h4>
                                {{ offer.offer.companyDetails.companyName }}
                              </h4>
                              <p v-if="offer.offer.status !== 2">
                                {{ offer.offer.companyDetails.industry.name }}
                              </p>
                            </div>
                            <!-- comment-f1 -->
                            <div
                              v-if="
                                offer.offer.companyDetails.companyDescription &&
                                offer.offer.status !== 2
                              "
                              class="company-description mb-4"
                            >
                              <h6>Description</h6>
                              <p>
                                {{
                                  offer.offer.companyDetails.companyDescription
                                }}
                              </p>
                            </div>
                            <!--comment-f1 : Not Displaying if the offer status is collaboration -->
                            <div
                              v-if="
                                offer.offer.companyDetails.companyURL &&
                                offer.offer.status !== 2
                              "
                              class=""
                              style="color: #0a7380"
                            >
                              <p>{{ offer.offer.companyDetails.companyURL }}</p>

                              <p
                                v-if="
                                  offer.offer.companyDetails.companyLinkedinUrl
                                "
                              >
                                {{
                                  offer.offer.companyDetails.companyLinkedinUrl
                                }}
                              </p>
                            </div>
                            <p class="text-capitalize">
                              <span
                                >{{
                                  offer.offer.companyDetails
                                    .billingStreetAddress
                                }},</span
                              >
                              <span>
                                {{ offer.offer.companyDetails.billingPostCode }}
                                {{
                                  offer.offer.companyDetails.billingCity
                                }},</span
                              >
                              <span>
                                {{
                                  offer.offer.companyDetails.billingCountry
                                }}</span
                              >
                            </p>
                          </div>
                          <div
                            v-if="!offer.offer.companyDetails.imageUrl"
                            class="align-self-center flex-grow-1"
                            style="line-height: 1.5rem"
                          >
                            <p
                              class="negotations-company-title text-capitalize"
                            >
                              {{ offer.offer.companyDetails.companyName }}
                            </p>
                            <p class="negotations-company-address mb-2">
                              {{
                                offer.offer.companyDetails.billingStreetAddress
                              }},
                              {{ offer.offer.companyDetails.billingPostCode }}
                              {{ offer.offer.companyDetails.billingCity }},
                              {{ offer.offer.companyDetails.billingCountry }}
                            </p>
                          </div>
                          <div class="text-center">
                            <!-- comment-f21 - moved here -->
                            <button
                              type="button"
                              @click="
                                changeOfferStatues(
                                  offer,
                                  offer.offer.status + 1
                                )
                              "
                              :disabled="!offer.offer.arrangedInterview"
                              class="btn btn-primary btn-sm text-white"
                              v-if="offer.offer.status === 2"
                            >
                              Confirm collaboration
                            </button>
                            <br />
                            <button
                              type="button"
                              @click="changeOfferStatues(offer, -2)"
                              class="btn btn-link-purple btn-sm p-0 mt-2"
                              v-if="
                                offer.offer.status > 1 && offer.offer.status < 3
                              "
                            >
                              Decline invitation
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- status -->
                      <div class="col-12 offer-details">
                        <div class="row">
                          <div
                            class="negotations-status-student"
                            v-if="offer.offer.status === 1"
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              You need to accept or decline the invitation.
                            </div>
                          </div>
                          <div
                            class="negotations-status-student"
                            v-if="offer.offer.status === 2"
                            :class="
                              offer.offer.status === 2 &&
                              offer.offer.arrangedInterview
                                ? ''
                                : 'grey-bg'
                            "
                          >
                            <div
                              class="negotations-status-title"
                              :class="
                                offer.offer.status === 2 &&
                                offer.offer.arrangedInterview
                                  ? ''
                                  : 'text-dark'
                              "
                            >
                              Status
                            </div>
                            <div
                              class="negotations-status-sub-title"
                              :class="
                                offer.offer.status === 2 &&
                                offer.offer.arrangedInterview
                                  ? ''
                                  : 'text-dark'
                              "
                              v-if="!offer.offer.arrangedInterview"
                            >
                              <!-- <span style="font-weight: 200"> -->
                              <!-- You need to confirm or decline the collaboration -->
                              Waiting on the company to contact you in regards
                              to arranging the interview.
                              <!-- Contact
                                {{ offer.offer.companyDetails.companyName }} at
                                {{ offer.offer.user.email }} or
                                {{ offer.offer.user.phoneNumber }} to arrange
                                the interview. -->
                              <!-- </span> -->
                              <br />
                              <!-- <p class="mt-2" style="font-weight: 600">
                                <i class="fas fa-exclamation-triangle"></i>
                                Only when you have had the interview with
                                {{ offer.offer.companyDetails.companyName }}
                                should you confirm the collaboration.
                              </p> -->
                            </div>
                            <div
                              class="negotations-status-sub-title"
                              :class="
                                offer.offer.status === 2 &&
                                offer.offer.arrangedInterview
                                  ? ''
                                  : 'text-dark'
                              "
                              v-if="offer.offer.arrangedInterview"
                            >
                              You need to confirm or decline the collaboration
                            </div>
                          </div>
                          <div
                            class="negotations-status"
                            v-if="offer.offer.status === 3"
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              waiting on
                              {{ offer.offer.companyDetails.companyName }} to
                              accept or decline the collaboration.
                            </div>
                          </div>
                          <div
                            v-if="offer.offer.status === 4"
                            class="negotations-status-student"
                          >
                            <div class="confirmed-status">Confirmed!</div>
                          </div>
                          <div
                            class="negotations-status-student"
                            v-if="offer.offer.status === 0"
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              Cancelled by company
                            </div>
                          </div>
                          <div
                            class="negotations-status-student"
                            v-if="
                              offer.offer.status === -1 ||
                              offer.offer.status === -2
                            "
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              Declined by students
                            </div>
                          </div>
                        </div>
                        <!--progress bar  -->
                        <div
                          class="col-12 offer-steps"
                          v-if="offer.offer.status > 0"
                        >
                          <ul>
                            <li class="activated" title="Step 1 description">
                              Invitation
                              <div class="step"></div>
                            </li>
                            <li
                              :class="
                                offer.offer.status === 2 ||
                                offer.offer.status > 2
                                  ? 'activated'
                                  : ''
                              "
                              title="Step 2 description"
                            >
                              Interview
                              <div class="step"></div>
                            </li>
                            <li
                              :class="
                                offer.offer.status === 3 ||
                                offer.offer.status > 3
                                  ? 'activated'
                                  : ''
                              "
                              title="Step 3 description"
                            >
                              Confirmation
                              <div class="step"></div>
                            </li>
                            <li
                              :class="
                                offer.offer.status === 4 ? 'activated' : ''
                              "
                              title="Step 4 description"
                            >
                              Collaboration
                              <div class="step"></div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="row negotations-request">
                        <div class="col-6">
                          <div v-if="offer.offer.createdDate">
                            <p class="negotations-request-title">
                              Interview request received
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(offer.offer.createdDate.seconds).format(
                                  "DD-MM-Y"
                                )
                              }}
                            </p>
                          </div>
                          <div
                            v-if="
                              (offer.offer.status === 2 ||
                                offer.offer.status > 2) &&
                              offer.offer.acceptedByStudentAt
                            "
                          >
                            <p class="negotations-request-title">
                              Invitation accepted by students
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(
                                  offer.offer.acceptedByStudentAt.seconds
                                ).format("DD-MM-Y")
                              }}
                            </p>
                            <p
                              class="negotations-request-title"
                              v-if="offer.offer.arrangedInterview"
                            >
                              Interview Arranged
                            </p>
                            <p
                              class="negotations-request-sub-title"
                              v-if="offer.offer.arrangedInterview"
                            >
                              {{ offer.offer.arrangedInterview }}
                            </p>
                          </div>
                          <div
                            v-if="
                              (offer.offer.status === 3 ||
                                offer.offer.status > 3) &&
                              offer.offer.confirmedByStudentAt
                            "
                          >
                            <p class="negotations-request-title">
                              Collaboration confirmed by students
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(
                                  offer.offer.confirmedByStudentAt.seconds
                                ).format("DD-MM-Y")
                              }}
                            </p>
                          </div>
                          <div
                            v-if="
                              (offer.offer.status === 4 ||
                                offer.offer.status > 4) &&
                              offer.offer.confirmedByCompanyAt
                            "
                          >
                            <p class="negotations-request-title">
                              Collaboration confirmed by the company
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(
                                  offer.offer.confirmedByCompanyAt.seconds
                                ).format("DD-MM-Y")
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="col-6">
                          <p class="negotations-request-title">
                            Message from company
                          </p>
                          <p class="negotations-request-sub-title">
                            {{ offer.offer.offerDetails.offerDescription }}
                          </p>
                        </div>
                      </div>
                      <!-- comment-f1 -->
                      <div
                        class="negotations-company-header p-4"
                        v-if="offer.offer.status !== 2"
                      >
                        <div class="negotations-buttons">
                          <button
                            type="button"
                            @click="
                              changeOfferStatues(offer, offer.offer.status + 1)
                            "
                            class="btn btn-primary btn-sm"
                            v-if="offer.offer.status === 1"
                          >
                            Accept invitation
                          </button>
                          <!-- comment-f2 -->
                          <!-- <button
                            type="button"
                            @click="
                              changeOfferStatues(offer, offer.offer.status + 1)
                            "
                            class="btn btn-primary btn-sm text-white"
                            v-if="offer.offer.status === 2"
                          >
                            Confirm collaboration
                          </button> -->
                          <button
                            type="button"
                            class="btn btn-light btn-sm text-white"
                            v-if="offer.offer.status === 3"
                          >
                            Waiting for company..
                          </button>

                          <button
                            type="button"
                            @click="changeOfferStatues(offer, -1)"
                            class="btn-link-purple btn btn-sm p-0 mt-2"
                            v-if="
                              offer.offer.status > 0 && offer.offer.status < 2
                            "
                          >
                            Decline invitation
                          </button>
                          <!--comment-f2 : moving because of new design -->
                          <!-- <button
                            type="button"
                            @click="changeOfferStatues(offer, -2)"
                            class="btn btn-link-purple btn-sm p-0 mt-2"
                            v-if="
                              offer.offer.status > 1 && offer.offer.status < 3
                            "
                          >
                            Decline invitation
                          </button> -->
                          <button
                            type="button"
                            @click="changeOfferStatues(offer, -1)"
                            class="btn btn-link-purple btn-sm p-0 mt-2"
                            v-if="offer.offer.status === 3"
                          >
                            Cancel Collaboration
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>

                  <!-- professional side -->
                  <template v-if="isUserOffering">
                    <div
                      class="erow row d-flex"
                      v-for="(offer, index) in usersOffer"
                      :key="index"
                    >
                      <!-- comment-f1 -->
                      <div class="negotations-company-header p-2">
                        <div
                          v-if="
                            offer.offer.companyDetails.imageUrl &&
                            offer.offer.status !== 2
                          "
                          class="image-container mt-2 mb-3 align-self-center"
                        >
                          <img
                            :src="offer.offer.companyDetails.imageUrl"
                            alt="ComapanyLogo"
                            class=""
                          />
                        </div>
                        <div v-if="offer.offer.companyDetails.imageUrl">
                          <div
                            v-if="
                              offer.offer.companyDetails.industry &&
                              offer.offer.status !== 2
                            "
                            class="company-industry-section"
                          >
                            <h4 class="header-margin">
                              {{ offer.offer.companyDetails.companyName }}
                            </h4>
                            <!-- comment-f1 -->
                            <p class="mb-0" v-if="offer.offer.status !== 2">
                              {{ offer.offer.companyDetails.industry.name }}
                            </p>
                          </div>
                          <!-- comment-f1 -->
                          <div
                            v-if="
                              offer.offer.companyDetails.companyDescription &&
                              offer.offer.status !== 2
                            "
                            class="company-description"
                          >
                            <h6 class="header-margin">Description</h6>
                            <p class="mb-0">
                              {{
                                offer.offer.companyDetails.companyDescription
                              }}
                            </p>
                          </div>
                          <!-- comment-f1 -->
                          <div
                            v-if="
                              offer.offer.companyDetails.companyURL &&
                              offer.offer.status !== 2
                            "
                            class=""
                            style="color: #0a7380"
                          >
                            <p class="header-margin">
                              {{ offer.offer.companyDetails.companyURL }}
                            </p>

                            <p
                              v-if="
                                offer.offer.companyDetails.companyLinkedinUrl
                              "
                              class="mb-0"
                            >
                              {{
                                offer.offer.companyDetails.companyLinkedinUrl
                              }}
                            </p>
                          </div>

                          <p
                            class="text-capitalize header-margin"
                            v-if="offer.offer.status !== 2"
                          >
                            <span
                              >{{
                                offer.offer.companyDetails.billingStreetAddress
                              }},</span
                            >
                            <span>
                              {{ offer.offer.companyDetails.billingPostCode }}
                              {{
                                offer.offer.companyDetails.billingCity
                              }},</span
                            >
                            <span>
                              {{
                                offer.offer.companyDetails.billingCountry
                              }}</span
                            >
                          </p>
                        </div>
                        <div
                          v-if="
                            offer.offer.status === 2 &&
                            user.subscription === true
                          "
                          class="d-flex justify-content-between"
                        >
                          <div
                            class="align-self-center"
                            style="line-height: 1.5rem"
                          >
                            <p
                              class="negotations-company-title text-center text-capitalize"
                            >
                              {{ offer.offer.companyDetails.companyName }}
                            </p>
                            <p
                              class="negotations-company-address mb-2 text-center"
                            >
                              {{
                                offer.offer.companyDetails.billingStreetAddress
                              }},
                              {{ offer.offer.companyDetails.billingPostCode }}
                              {{ offer.offer.companyDetails.billingCity }},
                              {{ offer.offer.companyDetails.billingCountry }}
                            </p>
                          </div>
                          <div class="">
                            <div
                              v-if="
                                offer.offer.status === 2 &&
                                user.subscription === true &&
                                !offer.offer.arrangedInterview
                              "
                              class="negotations-company-header p-4"
                            >
                              <div class="negotations-buttons">
                                <button
                                  type="button"
                                  class="btn btn-primary btn-block px-4 text-white"
                                  :disabled= "!thesisSlot"
                                  @click="
                                    updateCollaborationArrangedInterview(
                                      offer.id
                                    )
                                  "
                                >
                                  Confirm Arranged Interview
                                </button>
                                <button
                                  type="button"
                                  @click="changeOfferStatues(offer, 0)"
                                  class="btn btn-link-purple btn-sm p-0 mt-2"
                                >
                                  Cancel invitation
                                </button>
                              </div>
                            </div>
                            <div
                              v-if="
                                offer.offer.status !== 3 &&
                                offer.offer.status < 3 &&
                                offer.offer.arrangedInterview
                              "
                              class="negotations-company-header p-4"
                            >
                              <div class="negotations-buttons">
                                <button
                                  type="button"
                                  class="btn btn-light btn-block px-4 text-white"
                                >
                                  Confirm collaboration
                                </button>
                                <button
                                  type="button"
                                  @click="changeOfferStatues(offer, 0)"
                                  class="btn btn-link-purple btn-sm p-0 mt-2"
                                >
                                  Cancel invitation
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- status -->
                      <div class="col-12 offer-details">
                        <div class="row">
                          <div
                            class="negotations-status"
                            v-if="offer.offer.status === 1"
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              Waiting on students to accept or decline
                              invitation.
                            </div>
                          </div>
                          <!-- comment-f3 : updating content  -->
                          <!-- <div
                            v-if="
                              user.role === 'Professional' &&
                                user.company.length > 0 &&
                                !user.subscription &&
                                collaboration.status !== 'collaborating'
                            "
                          >
                            <button
                              class="btn btn-primary btn-lg"
                              @click="handleSubscription"
                            >
                              Select Subscription Plan<br />
                              <small>To start collaborating</small>
                            </button>
                           </div>  -->
                          <!-- <h1
                            v-if ="user.role === 'Professional' &&
                            user.company.length >0 &&
                            !user.subscription &&
                            collaboration.status === 'published'
                            "
                          >Correct</h1> -->
                          <div
                            class="negotations-status pink-bg"
                            v-if="offer.offer.status === 2"
                          >
                            <div class="negotations-status-title text-white">
                              Status
                            </div>
                            <div
                              class="negotations-status-sub-title text-white"
                              v-if="user.subscription === false"
                            >
                              Students have accepted the interview request. You
                              need to select a subscription plan to get their
                              contact details to proceed with the collaboration.
                              <br />
                              <!-- <p class="my-2">
                                After the interview, students will confirm or
                                decline the collaboration from their side.
                              </p> -->
                            </div>
                            <div
                              class="negotations-status-sub-title text-white"
                              v-if="
                                user.subscription === true &&
                                !offer.offer.arrangedInterview
                              "
                            >
                              Students have accepted the interview request. Here
                              is their contact information:
                              <br />
                              <br />
                              {{ collaborationMembers[0].members.email }}
                              <br />
                              <br />
                              REMEMBER to confirm the interview once it has been
                              arranged
                            </div>
                            <div
                              class="negotations-status-sub-title text-white"
                              v-if="
                                user.subscription === true &&
                                offer.offer.arrangedInterview
                              "
                            >
                              The students need to confirm or decline the
                              collaboration
                              <br />
                              They are expected to do so after the interview
                            </div>
                          </div>
                          <div
                            class="negotations-status-student"
                            v-if="offer.offer.status === 3"
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              You need to confirm or decline the collaboration
                            </div>
                          </div>
                          <div
                            class="negotations-status-student"
                            v-if="offer.offer.status === 4"
                          >
                            <div class="confirmed-status">Confirmed!</div>
                          </div>
                          <div
                            class="negotations-status"
                            v-if="offer.offer.status === 0"
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              Cancelled by company
                            </div>
                          </div>
                          <div
                            class="negotations-status"
                            v-if="
                              offer.offer.status === -1 ||
                              offer.offer.status === -2
                            "
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              Declined by students
                            </div>
                          </div>

                          <div
                            class="col-12 col-lg-4 align-self-center text-right"
                            v-if="offer.offer.status === 3"
                          ></div>
                        </div>
                        <div
                          class="col-12 offer-steps"
                          v-if="offer.offer.status > 0"
                        >
                          <ul>
                            <li class="activated" title="Step 1 description">
                              Invitation
                              <div class="step"></div>
                            </li>
                            <li
                              :class="
                                offer.offer.status === 2 ||
                                offer.offer.status > 2
                                  ? 'activated'
                                  : ''
                              "
                              title="Step 2 description"
                            >
                              Interview
                              <div class="step"></div>
                            </li>
                            <li
                              :class="
                                offer.offer.status === 3 ||
                                offer.offer.status > 3
                                  ? 'activated'
                                  : ''
                              "
                              title="Step 3 description"
                            >
                              Confirmation
                              <div class="step"></div>
                            </li>
                            <li
                              :class="
                                offer.offer.status === 4 ? 'activated' : ''
                              "
                              title="Step 4 description"
                            >
                              Collaboration
                              <div class="step"></div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="row negotations-request">
                        <div class="col-5">
                          <div v-if="offer.offer.createdDate">
                            <p class="negotations-request-title">
                              Interview request sent
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(offer.offer.createdDate.seconds).format(
                                  "DD-MM-Y"
                                )
                              }}
                            </p>
                          </div>
                          <div
                            v-if="
                              (offer.offer.status === 2 ||
                                offer.offer.status > 2) &&
                              offer.offer.acceptedByStudentAt
                            "
                          >
                            <p class="negotations-request-title">
                              Invitation accepted by students
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(
                                  offer.offer.acceptedByStudentAt.seconds
                                ).format("DD-MM-Y")
                              }}
                            </p>
                          </div>
                          <!-- comment-f invitation date -->
                          <div
                            v-if="
                              (offer.offer.status === 2 ||
                                offer.offer.status > 2) &&
                              offer.offer.arrangedInterview
                            "
                          >
                            <p class="negotations-request-title">
                              Interview Arranged
                            </p>
                            <p class="negotations-request-sub-title">
                              {{ offer.offer.arrangedInterview }}
                            </p>
                          </div>
                          <div
                            v-if="
                              (offer.offer.status === 3 ||
                                offer.offer.status > 3) &&
                              offer.offer.confirmedByStudentAt
                            "
                          >
                            <p class="negotations-request-title">
                              Collaboration confirmed by students
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(
                                  offer.offer.confirmedByStudentAt.seconds
                                ).format("DD-MM-Y")
                              }}
                            </p>
                          </div>
                          <div
                            v-if="
                              (offer.offer.status === 4 ||
                                offer.offer.status > 4) &&
                              offer.offer.confirmedByCompanyAt
                            "
                          >
                            <p class="negotations-request-title">
                              Collaboration confirmed by the company
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(
                                  offer.offer.confirmedByCompanyAt.seconds
                                ).format("DD-MM-Y")
                              }}
                            </p>
                          </div>
                        </div>

                        <div class="col-7">
                          <p class="negotations-request-title">
                            Message to students
                          </p>
                          <p class="negotations-request-sub-title">
                            {{ offer.offer.offerDetails.offerDescription }}
                          </p>
                        </div>
                      </div>
                      <!-- comment-f : don't show if status is 2 as well -->
                      <div
                        v-if="
                          offer.offer.status !== 3 &&
                          offer.offer.status < 3 &&
                          offer.offer.status !== 2
                        "
                        class="negotations-company-header p-4"
                      >
                        <div class="negotations-buttons">
                          <button
                            type="button"
                            class="btn btn-light btn-block px-4 text-white"
                          >
                            Confirm collaboration
                          </button>
                          <button
                            type="button"
                            @click="changeOfferStatues(offer, 0)"
                            class="btn btn-link-purple btn-sm p-0 mt-2"
                          >
                            Cancel invitation
                          </button>
                        </div>
                      </div>
                      <!-- 
                      <div
                        class="row negotations-request"
                        style="color: black"
                        v-if="offer.offer.status === 3"
                      >
                        <div class="col-lg-8">
                          <p class="negotations-request-title">Order details</p>

                          <p class="pt-2">
                            Thank you for being a part of our {{user.subscriptionPlan}} subscription.
                          </p>
                          <p class="pt-2">
                            Communication from now on with the student should
                            take place outside of the Excelerate platform. We
                            hope the collaboration will prove to be fruitful for
                            both you and the student.
                          </p>
                          <p class="negotations-status-title">
                            By confirming the collaboration you will allocate 1 thesis slot from your subscription plan 
                            to this collaboration until the end date of the thesis collaboration
                          </p>
                          <div class="form-group">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="offerTerms"
                                v-model="terms"
                                value="terms"
                                required
                              />
                              <label
                                class="custom-control-label"
                                style="color: black"
                                for="offerTerms"
                                >By checking this, you accept our
                                <router-link to="/company/terms" target="_blank"
                                  ><u>terms and conditions </u></router-link
                                >
                                for collaborations and subscriptions</label
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4" style="text-align: center">
                          <button
                            type="button"
                            class="btn btn-primary btn-block"
                            :disabled="!terms"
                            @click="companyAcceptCollaboration(offer)"
                          >
                            Confirm collaboration
                          </button>
                          <button
                            type="button"
                            @click="changeOfferStatues(offer, 0)"
                            class="btn btn-link-purple btn-sm p-0 mt-3"
                          >
                            Cancel invitation
                          </button>
                        </div>
                      </div> -->
                      <div
                        class="row negotations-request"
                        :class="user.subscription && false ? 'bg-yellow' : ''"
                        style="color: black"
                        v-if="offer.offer.status === 3"
                      >
                        <div
                          class="col-lg-8"
                          :class="user.subscription && false ? 'col-lg-12' : ''"
                        >
                          <p class="negotations-request-title">Order details</p>
                          <p v-if="user.subscription && false">
                            Please be aware: Collaborating with this thesis
                            group requires you to upgrade your subscription
                            <br />
                            as you have 0 available thesis slots for the
                            {{ collaboration.targetSemester }}
                          </p>
                          <br />
                          <p v-if="user.subscription && false">
                            Collaboration is not possible without upgrading.
                          </p>
                          <p class="pt-2" v-if="user.subscription && true">
                            Thank you for being a part of our
                            {{ user.subscriptionPlan }} subscription.
                          </p>
                          <p class="pt-2" v-if="user.subscription && true">
                            Communication from now on with the student should
                            take place outside of the Excelerate platform. We
                            hope the collaboration will prove to be fruitful for
                            both you and the student.
                          </p>
                          <p
                            class="negotations-status-title"
                            v-if="user.subscription && true"
                          >
                            By confirming the collaboration you will allocate 1
                            thesis slot from your subscription plan to this
                            collaboration until the end date of the thesis
                            collaboration
                          </p>
                          <div
                            class="form-group"
                            v-if="user.subscription && true"
                          >
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="offerTerms"
                                v-model="terms"
                                value="terms"
                                required
                              />
                              <label
                                class="custom-control-label"
                                style="color: black"
                                for="offerTerms"
                                >By checking this, you accept our
                                <router-link to="/company/terms" target="_blank"
                                  ><u>terms and conditions </u></router-link
                                >
                                for collaborations and subscriptions</label
                              >
                            </div>
                          </div>
                          <div
                            class=""
                            style="text-align: center"
                            v-if="user.subscription && false"
                          >
                            <button type="button" class="btn btn-primary px-5">
                              Upgrade now
                            </button>
                            <br />
                            <button
                              type="button"
                              class="btn btn-link-purple btn-sm p-0"
                            >
                              Cancel collaboration
                            </button>
                          </div>
                        </div>
                        <div
                          class="col-lg-4"
                          style="text-align: center"
                          v-if="user.subscription && true"
                        >
                          <button
                            type="button"
                            class="btn btn-primary btn-block"
                            :disabled="!terms || !thesisSlot"
                            @click="companyAcceptCollaboration(offer)"
                          >
                            Confirm collaboration
                          </button>
                          <button
                            type="button"
                            @click="changeOfferStatues(offer, 0)"
                            class="btn btn-link-purple btn-sm p-0 mt-3"
                          >
                            Cancel invitation
                          </button>
                        </div>
                      </div>
                      <!-- offer start -->
                      <!-- <div
                        class="col-12 offer-details"
                        v-if="offer.offer.status === 3"
                       >
                        <div class="collaboration-summary">
                          <div class="row">
                            <div class="col-12 col-lg-8">
                              <h6>Order details</h6>
                              <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Service</th>
                                    <th>Student</th>
                                    <th class="text-right">Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(member,
                                    index) in collaborationMembers"
                                    :key="index"
                                  >
                                    <td>Student match</td>
                                    <td>
                                      {{ member.members.firstName }}
                                      {{ member.members.lastName }}
                                    </td>
                                    <td class="text-right">
                                      {{ matchPrice }}.00
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colspan="2" class="text-right total">
                                      Total
                                    </td>
                                    <td class="text-right total">
                                      €
                                      {{
                                        collaborationMembers.length *
                                          matchPrice
                                      }}.00,-
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                              <strong
                                >We will send an invoice to your billing email: </strong
                              ><span class="color-third">{{
                                offer.offer.companyDetails.billingEmail
                              }}</span
                              ><strong> and a copy to:</strong
                              ><span class="color-third">{{
                                offer.offer.user.email
                              }}</span>
                            </div>
                            <div class="col-12 col-lg-4">
                              <h6>Company details</h6>
                              <strong>{{
                                offer.offer.companyDetails.companyName
                              }}</strong
                              ><br />
                              Att: {{ offer.offer.user.firstName }}
                              {{ offer.offer.user.lastName }}<br />
                              {{
                                offer.offer.companyDetails.billingStreetAddress
                              }}<br />
                              {{ offer.offer.companyDetails.billingPostCode }}
                              {{ offer.offer.companyDetails.billingCity
                              }}<br />½
                              {{ offer.offer.companyDetails.billingCountry
                              }}<br />
                              <br />
                              VAT.:
                              {{
                                offer.offer.companyDetails
                                  .companyIdentificationNumber
                              }}
                              <hr />
                              <div class="form-group">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="offerTerms"
                                    v-model="terms"
                                    value="terms"
                                    required
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="offerTerms"
                                    >By checking this, you accept our
                                    <router-link
                                      to="/company/terms"
                                      target="_blank"
                                      ><u
                                        >terms and conditions for
                                        collaborations</u
                                      ></router-link
                                    ></label
                                  >
                                </div>
                              </div>
                              <button
                                type="button"
                                class="btn btn-primary btn-block"
                                :disabled="!terms"
                                @click="companyAcceptCollaboration(offer)"
                              >
                                Confirm collaboration
                              </button>
                              <button
                            type="button"
                            @click="changeOfferStatues(offer, 0)"
                            class="btn btn-link-purple btn-sm p-0 mt-3"
                          >
                            Cancel invitation
                          </button>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <!-- offer end -->
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <div class="widget mb-3">
              <div class="widget-header sticky">
                <h4 id="members">Members</h4>
                <div class="widget-navigation">
                  <span
                    class="widget-nav"
                    data-toggle="modal"
                    data-target="#collabInvite"
                    v-if="isUserOwner && collaborationOffers.length === 0"
                    ><i class="fas fa-paper-plane"></i> Invite team member</span
                  >
                </div>
              </div>
              <div class="widget-body">
                <div v-if="collaborationInvites.length > 0">
                  <!-- invites -->
                  <UsersInvites
                    :collaborationInvites="collaborationInvites"
                    :user="user"
                    :isUserOwner="isUserOwner"
                  ></UsersInvites>
                </div>
                <!-- members -->
                <Member
                  :profile="member.members"
                  :id="member.id"
                  :isUserOwner="isUserOwner"
                  :user="user"
                  :isUserOfferingViewingAproved="isUserOfferingViewingAproved"
                  v-for="(member, index) in collaborationMembers"
                  :key="index"
                  :index="index"
                  :remove="true"
                  @removeMember="rmMember"
                ></Member>
              </div>
            </div>
            <div
              class="modal fade"
              id="collabInvite"
              tabindex="-1"
              role="dialog"
              aria-labelledby="collabInvite"
              aria-hidden="true"
              v-if="isUserOwner"
            >
              <div
                class="modal-dialog modal-dialog-centered modal-md"
                role="document"
              >
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="collabInvite">
                      Invite team member
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <form @submit.prevent="sendInvitationOnCollaboration()">
                      <div class="form-row">
                        <div class="col-12 col-lg-8">
                          <input
                            type="email"
                            class="form-control"
                            placeholder="Enter email to invite"
                            v-model="inviteEmail"
                            required
                          />
                        </div>
                        <div class="col-12 col-lg-4">
                          <button class="btn btn-primary btn-block">
                            Invite
                          </button>
                        </div>
                      </div>
                      <div class="alert alert-danger mt-2" v-if="inviteError">
                        {{ inviteError }}
                      </div>
                      <div
                        class="alert alert-success mt-2"
                        v-if="inviteMessage"
                      >
                        {{ inviteMessage }}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <!-- Members -->
          </div>
          <div class="col-12 col-lg-3">
            <div class="collaboration-sidebar">
              <div class="collaboration-details">
                <img
                  :src="
                    '/assets/institutions/' +
                    collaboration.institution.id +
                    '.png'
                  "
                  class="img-fluid"
                />
                <h6>University</h6>
                {{ collaboration.institution.name }}

                <h6>Degree</h6>
                {{ collaboration.degree }}

                <h6>Faculty</h6>
                {{ collaboration.faculty }}

                <h6>Area</h6>
                {{ collaboration.area }}

                <h6>Number of students</h6>
                <template v-if="collaboration.members">
                  {{ collaboration.members.length }}
                </template>

                <h6>Desired Occupation</h6>
                <template v-if="desiredOccupation">
                  <ul class="desired-occupation">
                    <li
                      v-for="(dOccupation, index) in desiredOccupation"
                      :key="index"
                    >
                      {{ dOccupation }}
                    </li>
                  </ul>
                </template>

                <h6>Thesis semester</h6>
                {{ collaboration.targetSemester }}

                <template v-if="collaboration.firstStartDate">
                  <h6>First possible collaboration start date</h6>
                  {{
                    $moment(collaboration.firstStartDate).format("DD.MM.YYYY")
                  }}
                </template>
                <template v-if="collaboration.lastStartDate">
                  <h6>Last possible collaboration start date</h6>
                  {{
                    $moment(collaboration.lastStartDate).format("DD.MM.YYYY")
                  }}
                </template>
                <template v-if="collaboration.thesisHandInDate">
                  <h6>Thesis Hand in date</h6>
                  {{
                    $moment(collaboration.thesisHandInDate).format("DD.MM.YYYY")
                  }}
                </template>
                <template
                  v-if="
                    collaboration.thesisHandInDate &&
                    collaboration.lastStartDate
                  "
                >
                  <h6>Minimum collaboration time</h6>
                  {{
                    diff_weeks(
                      collaboration.thesisHandInDate,
                      collaboration.lastStartDate
                    )
                  }}
                  weeks
                </template>
                <h6>Thesis Group Speaks</h6>
                <span
                  v-for="(lang, index) in collaboration.languages"
                  :key="index"
                >
                  {{ lang
                  }}<template
                    v-if="
                      collaboration.languages.length > 0 &&
                      index + 1 !== collaboration.languages.length
                    "
                    >,
                  </template>
                </span>

                <div class="mt-4" v-if="collaboration.substatus !== 'ghost'">
                  <div
                    class="collaboration-action"
                    v-if="
                      (user.role === 'Professional' &&
                        user.company.length > 0 &&
                        !!user.subscription &&
                        !isUserOffering &&
                        collaboration.status !== 'collaborating') ||
                      (!isUserOfferingNegotiating &&
                        user.role === 'Professional' &&
                        user.company.length > 0 &&
                        !!user.subscription &&
                        collaboration.status !== 'collaborating')
                    "
                  >
                    <h6>Do you see the potential in this group?</h6>
                    <p>Then request an interview with the group</p>

                    <!-- https://github.com/vuejs/vue-router/pull/2098 issue open with vuejs vue router. -->
                    <router-link
                      :disabled="!thesisSlot"
                      :event="thesisSlot ? 'click' : ''"
                      :style="
                        !thesisSlot
                          ? 'cursor: default; color: black; background-color: #FBFBFB; border: 1px solid #E1E1E1'
                          : ''
                      "
                      :to="'/offer/' + $route.params.id"
                      class="btn btn-primary btn-md btn-action"
                    >
                      Request Interview
                    </router-link>
                  </div>
                  <div
                    class="collaboration-action"
                    v-if="
                      user.role === 'Professional' &&
                      user.company.length > 0 &&
                      isUserOffering &&
                      isUserOfferingNegotiating &&
                      user.role === 'Professional' &&
                      user.company.length > 0
                    "
                  >
                    <h6>Do you see the potential in this group?</h6>
                    <p>Then request an interview with the group</p>

                    <button
                      type="button"
                      class="btn btn-light btn-md btn-invited text-white"
                    >
                      Already Invited
                    </button>
                  </div>

                  <div
                    v-if="
                      user.role === 'Professional' &&
                      user.company.length == 0 &&
                      collaboration.status !== 'collaborating'
                    "
                  >
                    <button
                      class="btn btn-primary btn-lg"
                      @click="handleRegistration"
                    >
                      Register Company<br />
                      <small>To start collaborating</small>
                    </button>
                  </div>
                  <div
                    v-if="
                      user.role === 'Professional' &&
                      user.company.length > 0 &&
                      !user.subscription &&
                      collaboration.status !== 'collaborating'
                    "
                  >
                    <button
                      class="btn btn-primary btn-lg"
                      @click="handleSubscription"
                    >
                      Select Subscription Plan<br />
                      <small>To start collaborating</small>
                    </button>
                    <!-- <router-link
                      to="/subscription"
                      class="btn btn-primary btn-lg"                      
                    >
                      Select Subscription Plan<br />
                      <small>To start collaborating</small>
                    </router-link>                     -->
                  </div>
                  <!--  to register with excelerate -->
                  <div v-if="!user.userId">
                    <router-link to="/register" class="btn btn-primary btn-lg">
                      Create a profile <br />
                      <small>To start collaborating</small>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="user.subscription && !thesisSlot"
            :class="
              user.subscription && !thesisSlot
                ? 'no-thesis-banner text-center pt-4 fixed-top'
                : ''
            "
          >
            <!-- true/false will be later changed to thesis slots -->
            <p class="d-inline">
              Please be aware: Collaborating with this thesis group requires you
              to upgrade your subscription as you have 0 available thesis slots
              for the {{ collaboration.targetSemester }}
            </p>
            <router-link
              to="/subscription"
              class="btn btn-link-purple btn-sm px-5 d-inline"
            >
              Upgrade now
            </router-link>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-md-12 mt-4">
            <Loader :times="2"></Loader>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h4>Something broke!</h4>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { db } from "../../firebase";
import moment from "moment";
import MasterHeader from "@/components/MasterHeader.vue";
import Member from "@/components/Member.vue";
import UsersInvites from "@/components/UsersInvites.vue";
import Loader from "@/components/Loader.vue";
import SignUpModal from '../../components/signUpModal.vue';

// import { mapState } from 'vuex'
export default {
  name: "viewpublicproposal",

  data() {
    return {
      terms: false,
      inviteEmail: "",
      inviteError: "",
      inviteMessage: "",
      thesisSlot: true,
      matchPrice: 349,
      collaboration: {
        createdDate: "",
        institution: {
          //todo skeleton img!
          name: "",
          id: "undefined",
        },
      },
      collaborationsMemberList:
        this.$store.state.collaboration.collaborationMembers,
      newMemberEmail: "",
      data: {
        collapsedHeight: 350,
        readMore: "Read More",
        readLess: "Read Less",
        speed: 200,
      },
      isReadMore: true,
      isReadLess: false,
      isPageLoaded: this.isLoaded,
    };
  },
  components: {
    MasterHeader,
    Member,
    UsersInvites,
    Loader,
    SignUpModal,
  },
  firestore() {
    return {
      collaboration: db.collection("collaborations").doc(this.$route.params.id),
    };
  },
  computed: {
    ...mapState(["user"]),
        hasNewSignupFlow(){
      const vm = this
      const isValid = vm.$store.getters.currentProgress >= 0 ? true : false
      const isStudent = vm.$store.getters.role === 'Student'
        if (!isValid && isStudent) {
          localStorage.setItem('notSignedUp', 1);
        }
        if (isValid) {
          localStorage.removeItem('notSignedUp');
        }
      return true
    },
    isLoaded: function () {
      var result = false;
      var members = this.collaboration.members;
      if (typeof members !== typeof undefined) {
        // console.log('dbhcjsf')
        result = true;
      }
      return result;
    },
    isUserOwner: function () {
      //  console.log(this.collaboration.stepCompleted)
      var result = false;
      var members = this.collaboration.members;
      if (typeof members !== typeof undefined) {
        result = members.includes(this.user.userId);
      }
      return result;
    },
    collaborationMembers: function () {
      var result = this.$store.state.collaboration.collaborationMembers;
      return result;
    },
    desiredOccupation: function () {
      var result = [];
      if (typeof this.collaborationMembers != "undefined") {
        this.collaborationMembers.forEach((cm) => {
          // console.log(cm.members.jobs);
          if (typeof cm.members.jobs != "undefined") {
            result = result.concat(cm.members.jobs);
          }
        });
      }
      result = result.map((res) =>
        res.name == "All"
          ? res.catgeory_name + " ( " + res.name + " )"
          : res.name
      );

      result = result.filter((v, i, a) => a.indexOf(v) === i);
      result.sort(function (a, b) {
        return a.localeCompare(b);
      });
      return result;
    },
    collaborationInvites: function () {
      return this.$store.state.collaboration.collaborationInvites;
    },
    collaborationOffers: function () {
      return this.$store.state.collaboration.collaborationOffers;
    },
    isUserOffering: function () {
      var result = false;
      var userCompanyId = this.user.company;
      var companyOffers = this.collaborationOffers;
      //is users companyId matching any of the offering companies' id and is the user a pro role.
      if (
        typeof undefined !== typeof companyOffers &&
        this.user.role === "Professional"
      ) {
        companyOffers.forEach(function (offer, index) {
          // result =
          //   offer.offer.companyId === userCompanyId ||
          //   (offer.offer.companyId === userCompanyId &&
          //     offer.offer.status !== 0)
          //     ? true
          //     : true;
          if (
            offer.offer.companyId === userCompanyId ||
            (offer.offer.companyId === userCompanyId &&
              offer.offer.status !== 0)
          ) {
            result = true;
          }
        });
      }
      return result;
    },
    isUserOfferingNegotiating: function () {
      var vm = this;
      var result = false;
      var userCompanyId = this.user.company;
      var companyOffers = this.collaborationOffers;
      var activeNegotiations = [];
      //is users companyId matching any of the offering companies' id and is the user a pro role.
      if (
        typeof undefined !== typeof companyOffers &&
        this.user.role === "Professional"
      ) {
        companyOffers.forEach(function (offer, index) {
          //skyd mig
          if (
            offer.offer.companyId === userCompanyId &&
            offer.offer.status >= 1
          ) {
            activeNegotiations.push("1");
          }
        });
        if (activeNegotiations.length > 0) {
          result = true;
        }
      }

      return result;
    },
    isUserOfferingViewingAproved: function () {
      var result = false;
      var userCompanyId = this.user.company;
      var companyOffers = this.collaborationOffers;
      //is users companyId matching any of the offering companies' id and is the user a pro role.
      if (
        typeof undefined !== typeof companyOffers &&
        this.user.role === "Professional"
      ) {
        companyOffers.forEach(function (offer, index) {
          result =
            offer.offer.companyId === userCompanyId && offer.offer.status >= 3
              ? true
              : false;
        });
      }
      return result;
    },
    usersOffer: function () {
      const collaborationOffers =
        this.$store.state.collaboration.collaborationOffers;
      var userCompanyId = this.user.company;
      var result = {};

      if (
        typeof undefined !== typeof collaborationOffers &&
        this.user.role === "Professional"
      ) {
        result = collaborationOffers.filter((item) => {
          return item.offer.companyId === userCompanyId;
        });
      }
      return result;
    },
    isEditable: function () {
      var result = this.collaborationOffers;
      result = result.filter((res) => res.offer.status > 0);
      return result.length > 0 ? false : true;
    },
    userCompany: function () {
      return this.$store.getters.company;
    },
    subscriptionId: function () {
      return this.$store.getters.userSubscriptionId;
    },
  },
  methods: {
    handleSubscription() {
      this.user.selectedCollabByCompany = this.$route.params.id;
      // console.log(this.user.selectedCollabByCompany)
      this.$router.push("/subscription/");
    },
    handleRegistration() {
      this.user.selectedCollabByCompany = this.$route.params.id;
      this.$router.push("/company/new");
    },
    loadPageMeta() {
      var members = this.collaboration.members;
      if (typeof members !== typeof undefined) {
        // console.log('dbhcjsf')
        this.$store.dispatch(
          "getCollaborationMembers",
          this.collaboration.members
        );
        this.$store.dispatch(
          "getCollaborationInvitesByCollabId",
          this.$route.params.id
        );
        this.$store.dispatch(
          "getCollaborationOffersCollabId",
          this.$route.params.id
        );
      }
    },
    rmMember(member) {
      var upmc = this.collaboration.members.filter((cm) => cm != member);
      this.$store.dispatch("updateCollaborationMembers", {
        collaborationId: this.$route.params.id,
        members: upmc,
      });
      this.$store.dispatch(
        "getCollaborationMembers",
        this.collaboration.members
      );
      // var updatedCollaborationMember = this.collaborationMembers.map(cm=>cm.id);
    },
    sendInvitationOnCollaboration() {
      var vm = this;
      var pendingCollaborationInvites =
        this.$store.state.collaboration.collaborationInvites;
      var existingMembers =
        this.$store.state.collaboration.collaborationMembers;
      var isAlreadyInvited = false;

      vm.inviteError = "";
      vm.inviteMessage = "";

      pendingCollaborationInvites.forEach(function (member, index) {
        if (member.invites.targetEmail === vm.inviteEmail) {
          isAlreadyInvited = true;
        }
      });

      existingMembers.forEach(function (member, index) {
        if (member.members.email === vm.inviteEmail) {
          isAlreadyInvited = true;
        }
      });

      if (isAlreadyInvited) {
        this.inviteError = "User is already a member or invited";
      } else {
        db.collection("users")
          .where("email", "==", vm.inviteEmail)
          .get()
          .then(function (querySnapshot) {
            var collection = [];
            querySnapshot.forEach((doc) => {
              collection.push({ id: doc.id, user: doc.data() });
            });

            var data = {
              collaborationId: vm.$route.params.id,
              collaborationTitle: vm.collaboration.title,
              sender: vm.user.firstName + " " + vm.user.lastName,
              senderEmail: vm.user.email,
              targetEmail: vm.inviteEmail,
            };

            if (collection.length > 0) {
              //if user already exists
              if (collection[0].user.role === "Student") {
                //MAIL 'Student - Invitation Recieved, already a user' @firstName, lastName, param1: firstName (receiver), param2: collaboration.title, param3: collaboration.id
                const templateData = {
                  toEmail: vm.inviteEmail,
                  firstName: collection[0].user.firstName,
                  param1: vm.user.firstName,
                  param2: vm.user.lastName,
                  param3: vm.collaboration.title,
                  param4: vm.$route.params.id,
                  template: "Student - Invitation Recieved, already a user",
                };
                vm.$store.dispatch("sendMail", templateData);
                vm.$store.dispatch("inviteUserToCollabortion", data);
                vm.$store.dispatch(
                  "getCollaborationInvitesByCollabId",
                  vm.$route.params.id
                );

                vm.inviteMessage = "Invite send.";
              } else {
                vm.inviteError = "The user you are inviting is not a student"; //not sending or dispatching anything
              }
            } else {
              // user does not exist
              //MAIL 'Student - Invitation Recieved, not a user' @firstName (this user), lastName (this user), param1: collaboration.title
              const templateData = {
                toEmail: vm.inviteEmail,
                firstName: vm.user.firstName,
                lastName: vm.user.lastName,
                param1: vm.collaboration.title,
                template: "Student - Invitation Recieved, not a user",
              };
              vm.$store.dispatch("sendMail", templateData);
              vm.$store.dispatch("inviteUserToCollabortion", data);
              vm.$store.dispatch(
                "getCollaborationInvitesByCollabId",
                vm.$route.params.id
              );
              vm.inviteMessage = "Invite send.";
            }
            vm.inviteEmail = ""; //clear email in form
          });
      }
    },

    changeStatus(newStatus) {
      const data = {
        id: this.$route.params.id,
        newStatus: newStatus,
        user: this.user,
        redirect: false,
      };
      this.$store.dispatch("updateCollaborationStatus", data);
    },

    acceptInvitation(invite) {
      var vm = this;
      var data = {
        collaborationId: this.$route.params.id,
        inviteId: invite.id,
        existingMembers: this.collaboration.members,
        interests: this.user.interests,
      };
      vm.$store.dispatch("acceptCollaborationInvitation", data);

      //MAIL 'Student - New Student Member Joined'
      const templateData = {
        toEmail: invite.invites.senderEmail,
        firstName: vm.user.firstName,
        lastName: vm.user.lastName,
        param1: vm.collaboration.title,
        param2: vm.$route.params.id,
        template: "Student - New Student Member Joined",
      };
      vm.$store.dispatch("sendMail", templateData);

      // this.$store.dispatch('getCollaborationInvitesByCollabId', this.$route.params.id);
    },
    declineInvitation(invite) {
      const data = {
        collabId: this.$route.params.id,
        inviteId: invite.id,
      };
      // if(invite.invites.targetEmail == this.user.email){
      const templateData = {
        toEmail: invite.invites.targetEmail,
        param1: this.user.firstName,
        template: "Student - Declined invitation",
      };
      this.$store.dispatch("sendMail", templateData);
      // }
      // console.log(invite);
      this.$store.dispatch("deleteCollaborationInviteById", data);
      // this.$store.dispatch('getCollaborationInvitesByCollabId', this.$route.params.id); // deleteCollaborationInviteById does this now..
    },
    removeMember(user) {
      var vm = this;
      var existingMembers = this.collaboration.members;
      var membersLeft = existingMembers.filter((member) => {
        return member !== user.id;
      });
      var data = {
        collaborationId: this.$route.params.id,
        members: membersLeft,
      };

      vm.$store.dispatch("updateCollaborationMembers", data);
      vm.$store.dispatch("getCollaborationMembers", membersLeft);

      //MAIL 'Student - Removed team member' @param1: firstName (receiver), param2: collaboration.title
      const templateData = {
        toEmail: user.members.email,
        firstName: user.members.firstName,
        param1: vm.collaboration.title,
        template: "Student - Removed team member",
      };
      vm.$store.dispatch("sendMail", templateData);
    },
    // setting a date for the arranged interview
    updateCollaborationArrangedInterview: function (offerId) {
      var vm = this;
      var today = moment();
      var arrangedInterview = today.format("DD-MM-Y");
      var data = {
        offerId: offerId,
        arrangedInterview: arrangedInterview,
      };
      vm.$store.dispatch("updateCollaborationArrangedInterview", data);
    },
    changeOfferStatues: function (offer, status) {
      var vm = this;
      var existingMembers = this.collaborationMembers;

      //2 . students confirm
      //0 - company cancels
      //-1 student cancels
      //the order in the if statements are a mess - I know ;)
      const data = {
        id: offer.id,
        newStatus: status,
      };
      //step 2
      if (status === 3) {
        if (
          confirm(
            "We hope you had a great interview! \n\nYou are accepting on behalf of the whole group.\nDo you wish to continue?"
          )
        ) {
          //MAIL 'Student - Collaboration confirmed' -> TO EVERY MEMBERS @firstName (receiver - student firstName), param1: collaboration.title, param2: company.name
          existingMembers.forEach(function (member, index) {
            const templateData = {
              toEmail: member.members.email,
              firstName: member.members.firstName,
              param1: vm.collaboration.title,
              param2: offer.offer.companyDetails.companyName,
              template: "Student - Collaboration confirmed by students",
            };
            vm.$store.dispatch("sendMail", templateData);
          });

          //MAIL 'Professional - Collaboration confirmed by students' -> TO COMPANY USER @param1: firstName (receiver - company firstName), param1: collaboration.title
          const templateData = {
            toEmail: offer.offer.user.email,
            firstName: offer.offer.user.firstName,
            param1: vm.collaboration.title,
            param2: vm.$route.params.id,
            template: "Professional - Collaboration confirmed by students",
          };
          vm.$store.dispatch("sendMail", templateData);

          dispatch();
        }
      } else if (status === 2) {
        //MAIL 'Student - Meeting request Accepted' -> TO EVERY MEMBERS @param1: firstName (receiver - student firstName), param1: collaboration.title, param2: company.name, param3: company.user.firstName, param4: company.user.lastName, param5: company.user.email, param6: company.user.phoneNumber
        existingMembers.forEach(function (member, index) {
          const templateData = {
            toEmail: member.members.email,
            firstName: member.members.firstName,
            param1: vm.collaboration.title,
            param2: offer.offer.companyDetails.companyName,
            param3: offer.offer.user.firstName,
            param4: offer.offer.user.lastName,
            param5: offer.offer.user.email,
            param6: offer.offer.user.phoneNumber,
            param7: vm.$route.params.id,
            template: "Student - Meeting request Accepted",
          };
          vm.$store.dispatch("sendMail", templateData);
        });

        //TODO: MAIL 'Professional - Meeting request Accepted' -> TO COMPANY USER @param1: firstName (receiver - company user firstName), param2: collaboration.title
        const templateData = {
          toEmail: offer.offer.user.email,
          firstName: offer.offer.user.firstName,
          param1: vm.collaboration.title,
          param2: vm.$route.params.id,
          template: "Professional - Meeting request Accepted",
        };
        vm.$store.dispatch("sendMail", templateData);

        dispatch();
      } else if (status === 4) {
        dispatch();
      } else if (status === -2) {
        /**
         * Fired : When students decline a collaboration
         * @student
         * Template : Student - Collaboration declined by students
         * @Professional
         * Template : Professional - Collaboration declined by students
         */
        if (
          confirm(
            "This will withdraw the invitation!\nDo you wish to continue?"
          )
        ) {
          existingMembers.forEach(function (member, index) {
            const templateData = {
              toEmail: member.members.email,
              firstName: member.members.firstName,
              param1: offer.offer.companyDetails.companyName,
              param2: vm.collaboration.title,
              // param3: vm.$route.params.id,
              template: "Student - Collaboration declined by students",
            };
            vm.$store.dispatch("sendMail", templateData);
          });

          //TODO: MAIL 'Professional - Meeting request declined' -> TO COMPANY USER @firstName (receiver - company user firstName), param1: collaboration.title
          const templateData = {
            toEmail: offer.offer.user.email,
            firstName: offer.offer.user.firstName,
            param1: vm.collaboration.title,
            template: "Professional - Collaboration declined by students",
          };
          vm.$store.dispatch("sendMail", templateData);
          dispatch();
        }
      } else if (status === -1) {
        // console.log(offer.offer, offer.offer.status);
        if (confirm("Decline invitation?\nDo you wish to continue?")) {
          //TODO: MAIL 'Student - Meeting request declined' -> TO EVERY MEMBER
          //param1: firstName (receiver - student firstName), param1: company.name, param2: collaboration.title
          existingMembers.forEach(function (member, index) {
            const templateData = {
              toEmail: member.members.email,
              firstName: member.members.firstName,
              param1: offer.offer.companyDetails.companyName,
              param2: vm.collaboration.title,
              param3: vm.$route.params.id,
              template: "Student - Meeting request declined",
            };
            vm.$store.dispatch("sendMail", templateData);
          });

          const templateData = {
            toEmail: offer.offer.user.email,
            firstName: offer.offer.user.firstName,
            param1: vm.collaboration.title,
            template: "Professional - Meeting request declined",
          };
          vm.$store.dispatch("sendMail", templateData);

          // var employees = offer.offer.companyDetails.companyEmployees;
          // // sending email to each company employees
          // employees.forEach(function(memberId, index) {
          //     var ref = db.collection("users").doc(memberId);
          //     ref.get()
          //     .then(function(querySnapshot) {
          //         const collection = querySnapshot.data();
          //         // Mail logics
          //         const templateData = {
          //             toEmail: querySnapshot.data().email,
          //             firstName: querySnapshot.data().firstName,
          //             param1: vm.collaboration.title,
          //             template: 'Professional - Collaboration declined by students',
          //         }
          //         vm.$store.dispatch('sendMail', templateData);
          //         // console.log({ "id":querySnapshot.id, "user": querySnapshot.data().email});
          //     })
          //     .catch(function(error) {
          //         console.log("Error getting documents: ", error);
          //     });
          // });
          // console.log(offer.offer,vm.user,offer.offer.companyDetails.companyEmployees);return false;
          dispatch();
        }
      } else if (status === 0) {
        if (confirm("Decline invitation?\nDo you wish to continue?")) {
          //TODO: MAIL 'Student - Meeting request declined' -> TO EVERY MEMBER
          //param1: firstName (receiver - student firstName), param1: company.name, param2: collaboration.title
          //
          existingMembers.forEach(function (member, index) {
            const templateData = {
              toEmail: member.members.email,
              firstName: member.members.firstName,
              param2: offer.offer.companyDetails.companyName,
              param1: vm.collaboration.title,
              template: "Student - Collaboration declined by company",
            };
            vm.$store.dispatch("sendMail", templateData);
          });
          // console.log(offer.offer,offer.offer.email);
          const templateData = {
            toEmail: offer.offer.user.email,
            firstName: offer.offer.user.firstName,
            param1: vm.collaboration.title,
            template: "Professional - Collaboration declined by company",
          };
          vm.$store.dispatch("sendMail", templateData);

          dispatch();
        }
      } else {
        dispatch();
      }

      function dispatch() {
        vm.$store.dispatch("updateCollaborationOfferStatus", data).then(() => {
          //settimeout is for getting the dates accepted by students.
          // setTimeout(()=>{
          vm.$store.dispatch(
            "getCollaborationOffersCollabId",
            vm.$route.params.id
          );
          // },1000)
        });
      }
    },

    companyAcceptCollaboration: function (offer) {
      const thesisData = {
        subscription_id: this.subscriptionId,
        company_id: this.user.company,
        thesis_semester: this.collaboration.targetSemester
      }
      var vm = this;
      var existingMembers = this.collaborationMembers;

      const data = {
        id: this.$route.params.id,
        newStatus: "collaborating",
        offerDetails: offer,
        members: this.$store.state.collaboration.collaborationMembers,
        matchPrice: this.matchPrice,
        acceptedBy: this.user.userId,
      };
      //create invoice!
      this.$store.dispatch("updateCollaborationStatus", data).then(() => {
        //TODO: MAIL 'Student - Collaboration Confirmed by all' -> TO EVERY MEMBERS
        //param1: firstName (receiver - student firstName), param1: company.name, param2: collaboration.title, param3: offer.offer.user.firstName (company user) param4: collaboration.targetSemester
        //! need to confirm if the api request needs to be made at this point or no, but for we'll do it here.
        this.$store.dispatch("setThesisSlots", thesisData);

        existingMembers.forEach(function (member, index) {
          const templateData = {
            toEmail: member.members.email,
            firstName: member.members.firstName,
            param1: offer.offer.companyDetails.companyName,
            param2: vm.collaboration.title,
            param3: offer.offer.user.firstName,
            param4: vm.collaboration.targetSemester,
            param5: vm.$route.params.id,
            template: "Student - Collaboration Confirmed by all",
          };
          vm.$store.dispatch("sendMail", templateData);
        });

        //TODO: MAIL 'Professional - Collaboration Confirmed'
        //TO COMPANY USER
        //param1: firstName (receiver - company firstName), param1: collaboration.title, param2: collaboration.targetSemester
        const templateData = {
          toEmail: offer.offer.user.email,
          firstName: offer.offer.user.firstName,
          param1: vm.collaboration.title,
          param2: vm.collaboration.targetSemester,
          param3: offer.offer.companyId,
          template: "Professional - Collaboration Confirmed",
        };
        vm.$store.dispatch("sendMail", templateData);

        //TODO: Mail
        //TO EVERY NEGOTIATORS- OTHER THAN THUS USERS COMPANY...
        // @firstname, param1: collaboration.title
        // set status -2

        vm.changeOfferStatues(offer, 4);
      });
    },
    //todo remove in MVP #1?
    // toggleFavorite: function(id) {
    //     var vm = this;
    //     if(vm.user.favorites.includes(id)) {
    //         vm.$store.dispatch('removeUserFavorite', id)
    //     } else {
    //         vm.$store.dispatch('addUserFavorite', id)
    //     }
    // },
    moment: function (seconds) {
      return moment.unix(seconds);
    },
    cutText: function (text, characters) {
      if (typeof text !== typeof undefined) {
        return text.length > characters
          ? text.substring(0, characters) + "..."
          : text;
      }
    },
    diff_weeks: function (dt2, dt1) {
      var diff = (new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
      diff /= 60 * 60 * 24 * 7;
      return Math.abs(Math.round(diff));
    },

    scan_div() {
      let elem = document.querySelector(".toggle-this");
      if (elem.clientHeight > this.data.collapsedHeight) {
        elem.style.height = this.data.collapsedHeight + "px";
        this.isReadMore = true;
      } else {
        this.isReadMore = false;
      }
    },
    gen_elem(data, cl) {
      return '<span class="' + cl + '">' + data + "</span>";
    },
    toggle() {
      let self = document.querySelector(".toggle-this");
      let ht = self.clientHeight;
      if (this.isReadMore) {
        this.isReadLess = true;
        this.isReadMore = false;
        self.style.height = "auto";
      } else {
        this.isReadMore = true;
        this.isReadLess = false;
        self.style.height = this.data.collapsedHeight + "px";
      }
    },

    // dt1 = new Date(2014,10,2);
    // dt2 = new Date(2014,10,11);
    // console.log(diff_weeks(dt1, dt2));
    getThesisSlots: function () {
      // if(this.user)
      if (this.user.subscriptionPlan.toLowerCase() == "beta") {
        this.thesisSlot = true;
      } else {
        const data = {
          company: this.userCompany,
          semester: this.collaboration.targetSemester,
          subscription: this.subscriptionId,
        };
        this.$store.dispatch("getThesisSlotsForSession", data).then((res) => {
          if (res === 0) {
            this.thesisSlot = false;
          } else if (res === 1) {
            this.thesisSlot = true;
          }
        });
      }
    },
  },
  watch: {
    collaboration: function (val) {
      if (typeof val.createdDate != "undefined") {
        this.loadPageMeta();
        this.getThesisSlots();
      }
      var self = this;
      setTimeout(function () {
        self.scan_div();
      }, 1000);
    },
  },
};
</script>

<style scoped>
.collaboration-thesis {
  padding: 1rem;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  border-radius: 4px;
  margin: 0;
}
.keywords {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.keyword {
  list-style: none;
  border: 1px solid #da518a;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  color: #da518a;
  font-size: 14px;
}
ul.desired-occupation {
  list-style: none;
  padding: 0;
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
}

ul.desired-occupation li {
  width: auto;
  margin-right: 0.6rem;
  border: 1px solid #0a7380;
  padding: 2px 6px;
  border-radius: 9px;
  margin-bottom: 6px;
  font-size: 14px;
  color: #0a7380;
}
.collaboration-action h6 {
  font-size: 18px;
}

.collaboration-action p {
  margin-top: 0.4rem;
  font-weight: 400;
}
.btn-action {
  background: #da518a;
  border-color: #da518a;
  padding: 0.4rem 2rem;
  border-radius: 12px;
  margin-bottom: 1rem;
}
.btn-invited {
  padding: 0.4rem 2rem;
  border-radius: 12px;
  margin-bottom: 1rem;
}
.erow-cta {
  border-radius: 4px;
  padding: 15px;
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
}
.erow .offer-details {
  margin: 0 0 0 0;
}
.offer-steps ul li {
  font-size: 12px;
}
@media (max-width: 800px) {
  .offer-steps ul li {
    font-size: 0.5rem;
  }
  .erow .offer-details {
    padding: 5px;
  }
}
.erow-cta h4 {
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 2px;
}
.toggle-this {
  overflow: hidden;
  position: relative;
}
.toggler {
  text-align: center;
  padding: 1rem 0 0 0;
  cursor: pointer;
}
.toggler .readMore {
  width: 100%;
  position: absolute;
  bottom: 0px;
  right: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
  padding: 155px 40px 0px;
}
.widget-header h4 {
  font-weight: 600;
  color: #000;
}
.request-interview h6 {
  margin-top: 0px;
}
.request-interview h5 {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.image-container img {
  width: 200px;
  align-self: center;
}
.negotations-company-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  color: #000;
}
.negotations-company-title {
  font-size: 28px;
  font-weight: 600;
  color: #000;
  padding-bottom: -2px;
  margin: 0;
}

.company-industry-section h4 {
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
}
.company-industry-section p {
  font-size: 12px;
  font-weight: 400;
}
.header-margin {
  margin: 12px 0 3px 0;
}
.company-description h6 {
  font-size: 14px;
  font-weight: 700;
}
.company-description p {
  font-size: 14px;
  font-weight: 400;
}
.negotations-company-address {
  font-size: 12px;
  color: #000;
}
.negotations-buttons {
  align-self: center;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}
.negotations-status {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  background-color: #fbfbfb;
  padding: 10px 13px;
  margin: 0 15px;
  width: 100%;
}
.negotations-status-student .confirmed-status {
  color: #fbfbfb;
}
.confirmed-status {
  color: #000;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
}
.negotations-status-student {
  border: 1px solid #d22d89;
  background-color: #d22d89;
  border-radius: 4px;
  padding: 10px 13px;
  margin: 0 15px;
  width: 100%;
}
.negotations-status-student .negotations-status-title,
.negotations-status-student .negotations-status-sub-title {
  color: white;
  font-size: 12px;
}
.negotations-status-student i {
  color: white;
  font-size: 30px;
  opacity: 0.8;
}
.negotations-status-title {
  font-weight: 700;
  color: #000;
}
.negotations-status-sub-title {
  font-size: 12px;
  color: #000;
}
.negotations-request {
  border: 1px solid #f1f1f1;
  padding: 24px 5px;
  margin: 12px 0 0;
  display: flex;
  width: 100%;
}
.negotations-request-title {
  font-weight: 700;
  font-size: 14px;
  color: #000;
  margin-bottom: 5px;
}
.negotations-request-sub-title {
  font-size: 12px;
  margin-top: 3px;
  color: #000;
}
.offer-steps {
  padding: 0;
}
.pink-bg {
  background-color: #d22d89;
}
.grey-bg {
  background-color: #fbfbfb !important;
  border: 1px solid #e1e1e1 !important;
}
.no-thesis-banner {
  background-color: #ffc561;
  width: 100%;
  height: 64px;
  font-size: 12px;
  bottom: 100;
}
.top-margin {
  margin-top: 55px;
}
.bg-yellow {
  background-color: #ffc561;
}
</style>